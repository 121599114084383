export default {
    // baseURL: '/sw/',
    // baseURL: 'http://172.18.0.103:8111/sw/',
    baseURL1: 'https://ump.xinyongyuan.com/saas/mp/admin/',
    baseURL: 'https://ump.xinyongyuan.com/',
    // baseURL:"http://117.160.222.7:7000/sw/",
    // baseURL: '192.168.1.50:60080/sw',
    // baseURL: 'http://172.18.0.90:60080/sw/',
    // baseURL: 'http://nas.xinyongyuan.com:60080/sw/',
    // baseURL: "http://zzclib.cn/sw/",
    // baseURL: "http://172.18.0.126:60080/sw/",
    isDev: true
}