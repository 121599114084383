import Env from './env';
import axios from 'axios'
import Qs from "qs";
axios.defaults.withCredentials = false;
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'; //配置请求
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.interceptors.request.use(config => {
    config.data = Qs.stringify(config.data);
    config.headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
    const token = localStorage.getItem("tokenlogin")
    if (token) {
        //config.params = { 'token': token } 
        config.headers.Authorization = token;
    }
    return config
}, error => {
    Promise.reject(error)
})
//基地址
export const ISDEV = Env.isDev;
//通用方法
export const POST = (url, params, type = {}) => {
    let base = localStorage.getItem("urlSchool") == null || url == "open/library/list" ? Env.baseURL1 : Env.baseURL + localStorage.getItem("urlSchool") + "/";
    return axios.post(`${base}${url}`, params, type).then(res => res.data)
}

export const GET = (url, params) => {
    let base = localStorage.getItem("urlSchool") == null || url == "open/library/list" ? Env.baseURL1 : Env.baseURL + localStorage.getItem("urlSchool") + "/";
    return axios.get(`${base}${url}`, {
        params: params
    }).then(res => res.data)
}

export const PUT = (url, params) => {
    let base = localStorage.getItem("urlSchool") == null || url == "open/library/list" ? Env.baseURL1 : Env.baseURL + localStorage.getItem("urlSchool") + "/";
    return axios.put(`${base}${url}`, params).then(res => res.data)
}

export const DELETE = (url, params) => {
    let base = localStorage.getItem("urlSchool") == null || url == "open/library/list" ? Env.baseURL1 : Env.baseURL + localStorage.getItem("urlSchool") + "/";
    return axios.delete(`${base}${url}`, {
        params: params
    }).then(res => res.data)
}

export const PATCH = (url, params) => {
    let base = localStorage.getItem("urlSchool") == null || url == "open/library/list" ? Env.baseURL1 : Env.baseURL + localStorage.getItem("urlSchool") + "/";
    return axios.patch(`${base}${url}`, params).then(res => res.data)
}